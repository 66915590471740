import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { supportedLanguages } from '../i18n';

import classNames from 'classnames';

import logoLobbyze from 'url:../assets/logo_lobbyze.svg';
import _888poker from 'url:../assets/888-poker.png';

import YoutubeEmbed from '../components/YoutubeEmbed';

const Desafio = () => {
  const { t, i18n } = useTranslation();

  const [fixedNavbar, setFixedNavbar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const [showLanguageTop, setShowLanguageTop] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleChangeLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
  };

  const handleActiveTab = (index) => () => {
    setActiveTab(index);
  };

  const toggleLanguageMenuTop = () => {
    setShowLanguageTop((oldValue) => !oldValue);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((oldValue) => !oldValue);
  };

  useEffect(() => {
    const listener = () => {
      if (document.body.scrollTop > 64 || document.documentElement.scrollTop > 64) {
        if (!fixedNavbar) setFixedNavbar(true);
      } else {
        if (fixedNavbar) setFixedNavbar(true);
      }
    };

    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const videoNode = useRef(null);
  const player = useRef(null);
  const initialized = useRef(false);

  useEffect(() => {
    if (videoNode.current && !initialized.current) {
      initialized.current = true; //prevent duplicate initialization
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...videoJsOptions
      }).ready(function () {
        console.log("Player Ready");
      });
    }
    //clear up player on dismount
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  return (
    <div className={classNames({ 'o-hidden': modalOpen || mobileMenuOpen })}>
      <nav className={classNames('navbar', { fixed: fixedNavbar })}>
        <div className="container">
          <a className="navbar__logo" href="/">
            <img src={logoLobbyze} alt={t('logoLobbyze')} />
          </a>
          <aside className={classNames('navbar__links', { open: mobileMenuOpen })}>
            <ul className="navbar__links__list">
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/#features">{t('features')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/plans">{t('pricing')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/#contato">{t('contact')}</a>
              </li>
              <li className="navbar__links__list__link">
                <a href="/faq">
                  {t('FAQ')}
                </a>
              </li>
              <li className="navbar__links__list__link link_desafio">
                <a href="/desafio10k">
                  Desafio 10k
                </a>
              </li>
              <li className="navbar__links__list__criar-conta">
                <a href="https://app.lobbyze.com/">
                  {t('tryItFree')}
                </a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="https://app.lobbyze.com">{t('login')}</a>
              </li>
              <li
                className={classNames('navbar__links__list__language', { open: showLanguageTop })}
                onClick={toggleLanguageMenuTop}
              >
                <button className="navbar__links__list__language__atual" type="button">
                  <span>{i18n.language}</span>
                </button>
                <div className="navbar__links__list__language__outros">
                  {supportedLanguages
                    .filter((lang) => lang != i18n.language)
                    .map((lang) => (
                      <a key={lang} onClick={handleChangeLanguage(lang)}>
                        {lang}
                      </a>
                    ))}
                </div>
              </li>
            </ul>
          </aside>
          <button
            className={classNames('navbar__menu-icon', { open: mobileMenuOpen })}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
          </button>
        </div>
      </nav>

      <section className="desafio-sec">
        <section id="desafio" className="desafio">
          <div className="container">
            <aside className="desafio__content">
              <header className="header-sec">
                <h2><span>REGLIFE</span></h2>
                <h2>DESAFIO 10K</h2>
                <a href="https://reglife.com.br/desafio10k/?utm_campaign=d10k&utm_medium=home&utm_source=lobbyze-org" target="_blank">Conheça o Desafio</a>
              </header>
              <h3>Todas as grades de torneios usadas pelo time da RegLife durante o Desafio 10K serão liberadas no Lobbyze. E você vai poder usá-las para montar o seu próprio desafio! 🔥</h3>
              <div className="list__container">
                <h5>IMPORTANTE</h5>
                <ul>
                  <li>Você deve criar a conta no Lobbyze usando o mesmo endereço de email que cadastrou no Desafio 10K. Ou seja, o endereço no qual você está lendo esse tutorial agora. Se você usar um email diferente, não terá acesso às grades.</li>

                  <li>A partir do momento que você criar a sua conta no Lobbyze, o prazo de liberação para o acesso à plataforma é de 48 horas.</li>

                  <li>O acesso ao Lobbyze e às grades será liberado apenas durante o período de realização do Desafio 10K.</li>
                </ul>
                <h3>Veja o vídeo abaixo sobre como acessar sua grade</h3>
              </div>
              <div className="video__container">
                <div className="video__container__box">
                  <YoutubeEmbed embedId="AGPaxTh5dwI" />
                </div>
              </div>
            </aside>
          </div>
        </section>
      </section>

      <footer id="footer-simple" className="footer-simple">
        <h6 className="footer-simple__obs">
          {t('copyright', { year: new Date().getFullYear() })}
          <a href="/privacy_policy.html" target="_blank" className="footer__privacy">{t('privacyPolicy')}</a>
        </h6>
      </footer>
    </div>
  );
};

export default Desafio;
