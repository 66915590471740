{
  "translation": {
    "lobbyze": "Lobbyze",
    "description": "Discover the best online poker tournaments in one place with Lobbyze. Set custom alerts, manage your earnings, and save time to focus on your game.",
    "logoLobbyze": "Logo Lobbyze",
    "features": "Features",
    "video": "Video",
    "plans": "Plans",
    "pricing": "Pricing",
    "contact": "Contact",
    "login": "Log In",
    "tryItFree": "Try it Free",
    "slide": [
      {
        "title": "The tournament grid of the <b>biggest sites in the world</b> in one place.",
        "description": "Have access to an extensive list of tournaments from the world's largest websites."
      },
      {
        "title": "Configure <b>personalized notifications</b> when registering.",
        "description": "It is possible to create push notifications in order to be reminded to register in the tournament. This notification is personalized to remind mintues before, minutes after the start of the tournament, etc."
      },
      {
        "title": "Manage <b>your earnings</b> with our personalized dashboards.",
        "description": "Follow the evolution of your Bankroll through daily releases of how your grind was and visualize the accumulated in graphics with modern interface."
      }
    ],
    "mainAction": "Start now",
    "mainActionSide": "Everything in one place",
    "notebook": "Notebook",
    "screen": "Screen",
    "learnFeatures": "Learn about Lobbyze features",
    "seeDemo": "See demo",
    "featuresList": [
      {
        "title": "Complete list of tournaments from the world's biggest websites",
        "description": "We bring you a complete list of the biggest sites in the world so you can build your tornament grid by extracting the best from each room into your game profile."
      },
      {
        "title": "Tournament Grid",
        "description": "Create a weekly tournament schedule and set up notifications so you don't forget to register for your favorite tournaments."
      },
      {
        "title": "Create custom filters according to your needs",
        "description": "Create custom filters to streamline the selection of tournaments that will make up your grid."
      },
      {
        "title": "Financial management of your grind",
        "description": "Keep track of tournaments played, prizes received and control your profits and losses."
      }
    ],
    "intro": "See what our platform looks like from the inside and start using it today",
    "introPlay": "Lorem ipsum dolor sit amet torquent consectetur adipiscing elit",
    "ourPlans": "Our Plans",
    "chooseBestPlan": "Choose the best plan for you",
    "startNow": "Start now",
    "pricePerMonth": "<span>$</span><b>{{price}} <span>/month</span></b>",
    "queriesPerMonth": "<b>{{count}}</b> query per month",
    "queriesPerMonth_plural": "<b>{{count}}</b> queries per month",
    "questionContactUs": "Any questions?<br />Get in touch with us!",
    "name": "Name",
    "email": "E-mail",
    "message": "Message",
    "phone": "Phone",
    "send": "Send",
    "copyright": "All rights reserved. Lobbyze © {{year}}",
    "privacyPolicy": "Privacy Policy",
    "termsOfService": "Terms of Service",
    "faq": "FAQ",
    "frequentlyAskedQuestions": "Frequently Asked Questions",
    "faqList": [
      {
        "title": "Is the license renewed automatically?",
        "description": "Yes. The license is going to be renewed until the user chooses to cancel it. To cancel your subscription find the user menu and click on subscription."
      },
      {
        "title": "Does Lobbyze list all tournaments from all categories?",
        "description": "No. For now we list only tournaments texas holde'm, and because the list is not generated from the tournament rooms themselves there's a chance come tournaments are missing, however all the main tournaments from aforementioned rooms are listed in the platform."
      },
      {
        "title": "Can I use a shared license?",
        "description": "No. The license is unique per user."
      }
    ],
    "clients": "Clients",
    "our_clients": "Our clients",
    "clientsTrust": "Trusted by the best",
    "supported_sites": "Supported sites",
    "free_description_1": "List of tournaments with buy ins up to 5 usd",
    "free_description_2": "Filter tournament list",
    "free_description_3": "Create tournament schedule",
    "free_description_4": "Create tournament start notification",
    "free_description_5": "Performance Management",
    "free_description_6": "Single user license",
    "small_description_1": "List of tournaments with buy ins up to 22 usd",
    "small_description_2": "Filter tournament list",
    "small_description_3": "Create tournament schedule",
    "small_description_4": "Create tournament start notification",
    "small_description_5": "Performance Management",
    "small_description_6": "Single user license",
    "full_description_1": "List of tournaments from all buy ins",
    "full_description_2": "Filter tournament list",
    "full_description_3": "Create tournament schedule",
    "full_description_4": "Create tournament start notification",
    "full_description_5": "Performance Management",
    "full_description_6": "Single user license",
    "buy": "buy",
    "free_highlight": "7 days test",
    "tier_box_from": "From",
    "tier_box_of": "",
    "tier_box_month": "/month",
    "tier_box_free_1": "WITH",
    "tier_box_free_2": "7 DAYS",
    "tier_box_free_3": "OF",
    "tier_box_free_4": "FULL PLAN",
    "tier_box_free_5": "ACCESS",
    "during_7_days": "For 7 days",
    "yearly_plan": "Yearly plan",
    "1_month_plan": "1 month plan",
    "3_months_plan": "3 months plan",
    "1_year_plan": "1 year plan",
    "monthly": "Monthly",
    "quarterly": "Quarterly",
    "yearly": "Yearly",
    "register": "Register"
  }
}
