{
    "translation": {
      "lobbyze": "Lobbyze",
      "description": "Откройте для себя лучшие турниры по онлайн-покеру в одном месте с помощью Lobbyze. Устанавливайте индивидуальные оповещения, управляйте своими доходами и экономьте время, чтобы сосредоточиться на игре.",
      "logoLobbyze": "Logo Lobbyze",
      "features": "Функции",
      "video": "Видео",
      "plans": "Планы",
      "pricing": "Ценообразование",
      "contact": "Контакт",
      "login": "Войти",
      "tryItFree": "Попробуйте бесплатно",
      "slide": [
        {
          "title": "Турнирная сетка <b>крупнейших площадок мира</b> в одном месте.",
          "description": "Иметь доступ к обширному списку турниров с крупнейших мировых сайтов."
        },
        {
          "title": "Настройте <b>персонализированные уведомления</b> при регистрации.",
          "description": "Можно создавать push-уведомления, чтобы напомнить о регистрации в турнире. Это уведомление персонализировано, чтобы напомнить минуты до, через несколько минут после начала турнира и т. Д."
        },
        {
          "title": "Управляйте <b>своими</b> доходов с помощью наших персонализированных панелей мониторинга.",
          "description": "Следите за эволюцией вашего банкролла через ежедневные выпуски того, как был ваш гринд, и визуализируйте накопленное в графике с современным интерфейсом."
        }
      ],
      "mainAction": "Начать сейчас",
      "mainActionSide": "Все в одном месте",
      "notebook": "Записная книжка",
      "screen": "Экран",
      "learnFeatures": "Подробнее о функциях Lobbyze",
      "seeDemo": "Посмотреть демонстрацию",
      "featuresList": [
        {
          "title": "Полный список турниров с крупнейших мировых сайтов",
          "description": "Мы предлагаем вам полный список крупнейших сайтов в мире, чтобы вы могли построить свою сетку, извлекая лучшее из каждой комнаты в свой игровой профиль."
        },
        {
          "title": "Турнирная сетка",
          "description": "Создайте еженедельное расписание турниров и настройте уведомления, чтобы не забыть зарегистрироваться на любимые турниры."
        },
        {
          "title": "Создание пользовательских фильтров в соответствии с вашими потребностями",
          "description": "Создавайте пользовательские фильтры, чтобы упростить выбор турниров, которые составят вашу сетку."
        },
        {
          "title": "Финансовый менеджмент вашего гринда",
          "description": "Следите за сыгранными турнирами, полученными призами и контролируйте свои прибыли и убытки."
        }
      ],
      "intro": "Посмотрите, как выглядит наша платформа изнутри и начните использовать ее уже сегодня",
      "introPlay": "Lorem ipsum dolor sit amet torquent consectetur adipiscing elit",
      "ourPlans": "Наши планы",
      "chooseBestPlan": "Выберите лучший план для вас",
      "startNow": "Начать сейчас",
      "pricePerMonth": "<span>$</span><b>{{price}} <span>/месяц</span></b>",
      "queriesPerMonth": "<b>{{count}}</b> запрос в месяц",
      "queriesPerMonth_plural": "<b>{{count}}</b> запросов в месяц",
      "questionContactUs": "Любые вопросы?<br />Свяжитесь с нами!",
      "name": "Имя",
      "email": "Электронная почта",
      "message": "Сообщение",
      "phone": "Телефон",
      "send": "Отправить",
      "copyright": "Все права защищены. Лоббиз {{year}}©",
      "privacyPolicy": "Политика конфиденциальности",
      "faq": "ВОПРОСЫ И ОТВЕТЫ",
      "frequentlyAskedQuestions": "Часто задаваемые вопросы",
      "faqList": [
        {
          "title": "Продлевается ли лицензия автоматически?",
          "description": "Да. Лицензия будет продлеваться до тех пор, пока пользователь не решит отменить ее. Чтобы отменить подписку, найдите меню пользователя и нажмите на подписку."
        },
        {
          "title": "Перечисляет ли Lobbyze все турниры из всех категорий?",
          "description": "Нет. На данный момент мы перечисляем только турниры texas holde'm, и поскольку список не генерируется из самих турнирных комнат, есть вероятность, что турниры пропадут, однако все основные турниры из вышеупомянутых комнат перечислены в платформе."
        },
        {
          "title": "Можно ли использовать общую лицензию?",
          "description": "Нет. Лицензия уникальна для каждого пользователя."
        }
      ],
      "clients": "Клиенты",
      "our_clients": "Наши клиенты",
      "clientsTrust": "Нам доверяют многие команды",
      "supported_sites": "Поддерживаемые сайты"
    }
  }