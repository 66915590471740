{
  "translation": {
    "lobbyze": "Lobbyze",
    "description": "Veja a grade de torneios de poker online em um só lugar com o Lobbyze. Receba alertas personalizados, gerencie seus ganhos e economize tempo para focar no jogo.",
    "logoLobbyze": "Logo Lobbyze",
    "features": "Recursos",
    "video": "Vídeo",
    "plans": "Planos",
    "pricing": "Preços",
    "contact": "Contato",
    "login": "Entrar",
    "tryItFree": "Testar Grátis",
    "slide": [
      {
        "title": "A grade de torneios dos <b>maiores sites do mundo</b> em um só lugar.",
        "description": "Tenha acesso a uma extensa lista de torneios dos maiores sites do mundo."
      },
      {
        "title": "Configure <b>notificações personalizadas</b> de hora de registro.",
        "description": "É possível criar push notifications para ser lembrado de registrar no torneio. Essa notificação é personalizada para avisar minutos antes, minutos depois do início do torneio, etc."
      },
      {
        "title": "Gerencie <b>seus ganhos</b> com nossos dashboards personalizados.",
        "description": "Acompanhe a evolução do seu bankroll através de lançamentos diários de como foi seu grind e visualize o acumulado em gráficos com interface moderna."
      }
    ],
    "mainAction": "Começar agora",
    "mainActionSide": "Tudo num só lugar",
    "notebook": "Notebook",
    "screen": "Tela",
    "learnFeatures": "Conheça as funcionalidades do Lobbyze",
    "seeDemo": "Ver demonstração",
    "featuresList": [
      {
        "title": "Lista completa de torneios dos maiores sites do Mundo",
        "description": "Trazemos uma lista completa dos maiores sites do mundo para que você possa montar a sua grade de torneios extraindo o melhor de cada sala para o seu perfil de jogo."
      },
      {
        "title": "Grade de torneios",
        "description": "Crie sua agenda semanal de torneio e configure notificações para não esquecer de se registrar nos seus torneios preferidos."
      },
      {
        "title": "Crie filtros personalizados de acordo com sua necessidade",
        "description": "Crie filtros personalizados para agilizar a seleção dos torneios que irão compor a sua grade."
      },
      {
        "title": "Faça a gestão financeira do seu grind",
        "description": "Faça controle dos torneios jogados, prêmios recebidos e controle seus lucros e perdas."
      }
    ],
    "intro": "Veja como é nossa plataforma por dentro e comece a usar hoje mesmo",
    "introPlay": "Lorem ipsum dolor sit amet torquent consectetur adipiscing elit",
    "ourPlans": "Nossos planos",
    "chooseBestPlan": "Escolha o melhor plano para você",
    "startNow": "Começar agora",
    "pricePerMonth": "<span>$</span><b>{{price}} <span>/mês</span></b>",
    "queriesPerMonth": "<b>{{count}}</b> consultas por mês",
    "queriesPerMonth_plural": "<b>{{count}}</b> consultas por mês",
    "questionContactUs": "Alguma dúvida?<br /> Entre em contato conosco",
    "name": "Nome",
    "email": "E-mail",
    "message": "Mensagem",
    "phone": "Telefone",
    "send": "Enviar",
    "copyright": "Todos os direitos reservados. Lobbyze © {{year}}",
    "privacyPolicy": "Política de privacidade",
    "termsOfService": "Termos de Uso",
    "faq": "FAQ",
    "frequentlyAskedQuestions": "Perguntas Frequentes",
    "faqList": [
      {
        "title": "A licença é renovada automaticamente?",
        "description": "Sim. A licença continuará se renovando até que o usuário faça o cancelamento. Para cancelar acesse o menu de usuário e clique em subscription."
      },
      {
        "title": "O lobbyze lista todos os torneios de todas as modalidades?",
        "description": "Não. Por enquanto listamos apenas torneios da modalidade de texas holde´m e como a lista não é gerada a partir das salas de poker em si, pode ocorrer de algum torneio ficar de fora, porém todos os principais torneios das salas são listados na plataforma."
      },
      {
        "title": "Eu consigo usar a licença compartilhada?",
        "description": "Não. A licença é única por usuário."
      }
    ],
    "clients": "Clientes",
    "our_clients": "Nossos clientes",
    "clientsTrust": "Utilizado pelos melhores",
    "supported_sites": "Sites suportados",
    "free_description_1": "Lista de torneios com buy ins em até 5 usd",
    "free_description_2": "Filtrar lista de torneios",
    "free_description_3": "Criar agendas de torneio",
    "free_description_4": "Criar notificação de início do torneio",
    "free_description_5": "Gerenciamento de Desempenho",
    "free_description_6": "Licença para usuário único",
    "small_description_1": "Lista de torneios com buy ins em até 22 usd",
    "small_description_2": "Filtrar lista de torneios",
    "small_description_3": "Criar agendas de torneio",
    "small_description_4": "Criar notificação de início do torneio",
    "small_description_5": "Gerenciamento de Desempenho",
    "small_description_6": "Licença para usuário único",
    "full_description_1": "Lista de torneios com todos os buy ins",
    "full_description_2": "Filtrar lista de torneios",
    "full_description_3": "Criar agendas de torneio",
    "full_description_4": "Criar notificação de início do torneio",
    "full_description_5": "Gerenciamento de Desempenho",
    "full_description_6": "Licença para usuário único",
    "buy": "comprar",
    "free_highlight": "7 dias de teste",
    "tier_box_from": "A partir",
    "tier_box_of": "de ",
    "tier_box_month": "/mês",
    "tier_box_free_1": "COM",
    "tier_box_free_2": "7 DIAS",
    "tier_box_free_3": "DE ACESSO AO",
    "tier_box_free_4": "PLANO FULL",
    "tier_box_free_5": "",
    "during_7_days": "Durante 7 dias",
    "yearly_plan": "Plano anual",
    "1_month_plan": "Plano de 1 mês",
    "3_months_plan": "Plano de 3 meses",
    "1_year_plan": "Plano de 1 ano",
    "monthly": "Mensal",
    "quarterly": "Trimestral",
    "yearly": "Anual",
    "register": "Cadastrar"
  }
}